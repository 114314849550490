<mat-card appearance="outlined" *ngIf="authedUser">
  <mat-card-content class="home-wrapper">

    <h1>{{'HOME_HEADING' | translate}}</h1>

    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>

      <div fxFlex="25">

        <h3>{{'HOME_ROLES_HEADING' | translate }}</h3>
        <mat-list>
          <mat-list-item *ngFor="let role of authedUser.roles | keyvalue">
            <span matListItemMeta>{{role.value['key']}}</span>
            <span matListItemLine>{{role.value['value']}}</span>
          </mat-list-item>
        </mat-list>

      </div>
      <div fxFlex="25">

        <h3>{{'HOME_ARCHIVES_HEADING' | translate }}</h3>
        <mat-list>
          <mat-list-item *ngFor="let role of authedUser.archives | keyvalue">
            <span matListItemMeta>{{role.value['key']}}</span>
            <span matListItemLine>{{role.value['value']}}</span>
          </mat-list-item>
        </mat-list>

      </div>
      <div fxFlex="25">

        <h3>{{'HOME_SENDINGSYSTEMS_HEADING' | translate }}</h3>
        <mat-list>
          <mat-list-item *ngFor="let role of authedUser.systems | keyvalue">
            <span matListItemMeta>{{role.value['key']}}</span>
            <span matListItemLine>{{role.value['value']}}</span>
          </mat-list-item>
        </mat-list>

      </div>

      <div fxFlex="25">

        <h3>{{'HOME_FUNCTIONS_HEADING' | translate }}</h3>
        <mat-list>
          <mat-list-item *ngFor="let role of authedUser.functions | keyvalue">
            <span matListItemMeta>{{role.value['key']}}</span>
            <span matListItemLine>{{role.value['value']}}</span>
          </mat-list-item>
        </mat-list>

      </div>

    </div>


  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="!authedUser">
  <mat-card-content>
    <p>{{'HOME_NOT_AUTHENTICATED' | translate}}..</p>
    <p>{{'HOME_NOT_AUTHENTICATED_ADDITIONAL' | translate}}</p>
  </mat-card-content>
</mat-card>
