<h1 mat-dialog-title>{{(data.title ? data.title : 'Confirm') | translate}}</h1>
<div mat-dialog-content>
  <p>{{data.message | translate}} {{data.identifier ? data.identifier + '?' : ''}}</p>
  <mat-card appearance="raised" class="warning-card" *ngIf="data.warningText">
    <mat-card-content>
      <span>
        <mat-icon class="mat-icon-card">warning</mat-icon>
        <span>{{ data.warningText | translate }}</span>
      </span>
    </mat-card-content>
  </mat-card>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
  <button mat-raised-button color={{data.okButtonColor}} [mat-dialog-close]="'ok'" cdkFocusInitial>{{(data.okButtonText ? data.okButtonText : 'Yes') | translate}}</button>
</div>
