import {KeyValue} from "./key-value.model";

export const FunctionAddCareUnits = "AddCareUnits";
export const FunctionDeleteXds = "DeleteXds";
export const FunctionAccessAuditLogs = "AccessAuditLogs";
export const FunctionAccessSystemLogs = "AccessSystemLogs";
export const FunctionConfigArchives = "ConfigArchives";
export const FunctionConfigBroadviews = "ConfigBroadviews";
export const FunctionConfigLibraries = "ConfigLibraries";
export const FunctionAccessTerminologyTool = "AccessTerminologyTool";
export const FunctionAccessErrorPortal = "AccessErrorPortal";
export const FunctionSystemStatus = "SystemStatus";
export const FunctionConfigRoles = "ConfigRoles";
export const FunctionCorrections = "Corrections";
export const FunctionExportData = "ExportData";
export const FunctionAccessXDSAdmin = "AccessXDSAdmin";
export const FunctionConfigDicomDevices = "ConfigDicomDevices";
export const FunctionManageCareUnits = "ManageCareUnits";
export const FunctionConfigReferenceData = "ConfigReferenceData";
export const FunctionTasks = "Tasks";
export const FunctionRegistrySync = "RegistrySync";
export const FunctionViewErrors = "ViewErrors";
export const FunctionResolveErrors = "ResolveErrors";
export const FunctionAccessErrorDashboard = "AccessErrorDashboard";
export const FunctionConfigCareProviders = "ConfigCareProviders";
export const FunctionSendDicom = "SendDicom";
export const FunctionRequestOverview = "RequestOverview";
export const FunctionCorrectionsXDS = "CorrectionsXDS";
export const FunctionCorrectionsHL7DB = "CorrectionsHL7DB";
export const FunctionCollaboration = "Collaboration";

export const AdminPortalFunctions = [
  FunctionAddCareUnits,
  FunctionDeleteXds,
  FunctionAccessAuditLogs,
  FunctionAccessSystemLogs,
  FunctionConfigArchives,
  FunctionConfigBroadviews,
  FunctionConfigLibraries,
  FunctionAccessTerminologyTool,
  FunctionAccessErrorPortal,
  FunctionSystemStatus,
  FunctionConfigRoles,
  FunctionCorrections,
  FunctionExportData,
  FunctionAccessXDSAdmin,
  FunctionConfigDicomDevices,
  FunctionManageCareUnits,
  FunctionConfigReferenceData,
  FunctionTasks,
  FunctionRegistrySync,
  FunctionViewErrors,
  FunctionResolveErrors,
  FunctionAccessErrorDashboard,
  FunctionConfigCareProviders,
  FunctionSendDicom,
  FunctionRequestOverview,
  FunctionCorrectionsXDS,
  FunctionCorrectionsHL7DB
];

export interface User {
  username?: string;
  /** @description Gets or sets the value of the sid */
  sid?: string;
  /** @description Gets or sets the value of the firstname */
  firstname?: string;
  /** @description Gets or sets the value of the lastname */
  lastname?: string;
  /** @description Gets or sets the value of the email */
  email?: string;
  /** @description Gets or sets the value of the user id */
  userId?: string;
  /** @description Gets or sets the value of the functions */
  functions?: KeyValue<any, string>[];
  /** @description Gets or sets the value of the systems */
  systems?: KeyValue<any, string>[];
  /** @description Gets or sets the value of the archives */
  archives?: KeyValue<any, string>[];
  /** @description Gets or sets the value of the roles */
  roles?: KeyValue<any, string>[];
  /** @description Gets or sets the value of the users ad groups */
  usersAdGroups?: (string)[];
  /** @description Gets or sets the value of the miu roles */
  healthCareUnitHsaId?: string;
  /** @description Gets or sets the value of the miu roles */
  healthCareUnitName?: string;
  /** @description Gets or sets the value of the current miu role */
  hsaIdentity?: string;
  /** @description Gets or sets the value of the current miu role */
  adIdentity?: string;
  userSettings?: UserSetting;
}

export interface UserSetting {
  userSettingId?: number;
  username?: string;
  errorPortalSearch?: string;
  userSettingDetails?: UserSettingDetail[];
}

export interface UserSettingDetail {

  id?: number;
  userSettingId?: number;
  type?:
    'saved_filter_work_list' |
    'saved_filter_work_list_send' |
    'is_always_show_detail' |
    'default_work_list_filters' |
    'default_work_list_columns' |
    'default_work_list_send_filters' |
    'default_work_list_send_columns' |
    'default_color_theme';
  groupName?: string;
  key?: string;
  value?: string;

}