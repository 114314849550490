<mat-card appearance="outlined" class="cgi-card" *ngIf="exportJob">
  <mat-card-content>

    <div class="buttons">
      <button mat-raised-button *ngIf="exportJob.status !== 'success'" color="primary" (click)="prepareExport()">{{'Prepare export' | translate}}</button>
      <button mat-raised-button *ngIf="exportJob.status === 'success'" (click)="download()">{{'Download' | translate}}</button>
    </div>

    <p>Type: {{exportJob.type}}</p>
    <p>Format: {{exportJob.format}}</p>
    <p>Start: {{exportJob.expireStartTime}}</p>
    <p>End: {{exportJob.expireEndTime}}</p>
    <p>maxNrDownloads: {{exportJob.maxNrDownloads}}</p>
    <p>createdDate: {{exportJob.createdDate}}</p>
    <p>createdBy: {{exportJob.createdBy}}</p>
    <p>status: {{exportJob.status}}</p>
    <p>errorMessage: {{exportJob.errorMessage}}</p>
    <p>isZipAfterFilesReady: {{exportJob.isZipAfterFilesReady}}</p>
    <p>identifier: {{exportJob.identifier}}</p>
    <p>patientId: {{exportJob.patientId}}</p>
    <p>Id: {{exportJob.id}}</p>
    <p>NrDownloads: {{exportJob.nrDownloads}}</p>




  </mat-card-content>
</mat-card>


