<mat-card appearance="outlined" class="cgi-card">
  <mat-card-content>

    <div *ngIf="availableWorkListItems && availableWorkListSentItems">

      <h2>{{'Your default filters and columns' | translate}}</h2>

      <div class="buttons">
        <button mat-raised-button color="primary" (click)="populateConfig('config_1')">{{'Config1' | translate}}</button>
        <button mat-raised-button color="primary" (click)="populateConfig('config_2')">{{'Config2' | translate}}</button>
        <button mat-raised-button color="primary" (click)="populateConfig('config_3')">{{'Config3' | translate}}</button>
      </div>

      <mat-divider class="m-t-10" inset="false"></mat-divider>

      <h3>{{'Work list' | translate}}</h3>

      <h4>{{'Filters' | translate}}</h4>
      <app-cgi-multiselect-drag [placeholderText]="'Select work list filters' | translate"
                       [(dataValue)]="availableWorkListItems"
                       [(selectedItems)]="selectedWorkListFilters">
      </app-cgi-multiselect-drag>


      <h4>{{'Columns' | translate}}</h4>
      <app-cgi-multiselect-drag [placeholderText]="'Select work list columns' | translate"
                       [(dataValue)]="availableWorkListItems"
                       [(selectedItems)]="selectedWorkListColumns">
      </app-cgi-multiselect-drag>


      <h4></h4>
      <mat-checkbox id="alwaysShowDetails" [(ngModel)] ="checkBoxAlwaysShowDetails">{{'Always show details' | translate}}</mat-checkbox>
      <h3></h3> <!--Tom header för att ge linjen plats att visas-->
      <mat-divider></mat-divider>

      <h3>{{'Sent List' | translate}}</h3>

      <h4>{{'sent filters' | translate}}</h4>
      <app-cgi-multiselect-drag [placeholderText]="'Select work list filters' | translate"
                       [(dataValue)]="availableWorkListSentItems"
                       [(selectedItems)]="selectedWorkListSentFilters">
      </app-cgi-multiselect-drag>

      <h4>{{'sent columns' | translate}}</h4>
      <app-cgi-multiselect-drag [placeholderText]="'Select work list filters' | translate"
                       [(dataValue)]="availableWorkListSentItems"
                       [(selectedItems)]="selectedWorkListSentColumns">
      </app-cgi-multiselect-drag>

      <mat-divider class="m-t-20"></mat-divider>
      <h4>{{'Theme' | translate}}</h4>
      <mat-button-toggle-group [disabled]="loadingUpdateProfile" (change)="changeTheme($event.value)" [(ngModel)]="selectedTheme" name="theme" aria-label="Theme selection">
        <mat-button-toggle value="light">{{'Light' | translate}}</mat-button-toggle>
        <mat-button-toggle value="dark">{{'Dark' | translate}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="m-t-20">
      <button mat-raised-button class="mat-primary" [class.spinner]="loadingUpdateProfile" [disabled]="loadingUpdateProfile" (click)="updateProfile()">{{'Update your profile' | translate}}</button>
    </div>

  </mat-card-content>

</mat-card>
