import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {MenuItems} from './menu-items/menu-items';
import {AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective} from './accordion';
import {CdkDetailRowDirective} from "./directive/cdk-detail-row.directive";
import {CommonModule} from "@angular/common";
import {CdkDrag, CdkDropList, CdkDropListGroup} from "@angular/cdk/drag-drop";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CgiMultiselectComponent} from "./component/cgi-multiselect/cgi-multiselect.component";
import {ConfirmDialogComponent} from "./dialog/confirm-dialog/confirm-send-dialog.component";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "./material-module";
import {OrderByPipe} from "./pipes/order-by.pipe";
import {FilterByPipe} from "./pipes/filter.pipe";
import {CgiMultiselectDragComponent} from "./component/cgi-multiselect-drag/cgi-multiselect-drag.component";
import {MultiSelectModule} from "primeng/multiselect";
import {MatSelectModule} from "@angular/material/select";
import {CgiSearchSelectComponent} from "./component/cgi-select-lazy-load/cgi-search-select.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {JsonDetailsDialogComponent} from "./dialog/json-details-dialog/json-details-dialog.component";
import {StringDetailsDialogComponent} from "./dialog/string-details-dialog/string-details-dialog.component";
import {CgiFilterComponent} from "./component/cgi-filter/cgi-filter.component";
import {FlexModule} from "@angular/flex-layout";
import {SaveFilterDialogComponent} from "./component/cgi-filter/save-filter-dialog/save-filter-dialog.component";


@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CdkDetailRowDirective,
    CgiMultiselectDragComponent,
    CgiMultiselectComponent,
    CgiFilterComponent,
    SaveFilterDialogComponent,
    ConfirmDialogComponent,
    JsonDetailsDialogComponent,
    StringDetailsDialogComponent,
    OrderByPipe,
    FilterByPipe,
    CgiSearchSelectComponent
  ],
  imports: [
    CommonModule,
    CdkDropList,
    CdkDrag,
    CdkDropListGroup,
    FormsModule,
    TranslateModule,
    MaterialModule,
    MultiSelectModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexModule
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CdkDetailRowDirective,
    CgiMultiselectDragComponent,
    CgiMultiselectComponent,
    CgiSearchSelectComponent,
    ConfirmDialogComponent,
    CgiFilterComponent,
    JsonDetailsDialogComponent,
    StringDetailsDialogComponent,
    FilterByPipe,
    CgiSearchSelectComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [MenuItems],
})
export class SharedModule {}
