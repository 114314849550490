import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {User, UserSettingDetail} from "../models/user.model";
import {OAuthService} from "angular-oauth2-oidc";
import {ApiService} from "./api.service";


@Injectable({
    providedIn: 'root'
})
export class UserService implements OnInit {


    private userSubject$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
    public user$: Observable<User | undefined> = this.userSubject$.asObservable();


    constructor(private oauthService: OAuthService, private apiService: ApiService) {}

    ngOnInit(): void {}

    listenToAuthedUser(): Observable<User | undefined> {
        return this.user$;
    }

    setUser(user?: User | undefined): User | undefined {
        this.userSubject$.next(user);
        return user;
    }

    logOut(): void {
        this.setUser(undefined);
        this.oauthService.logOut();

    }

    getUserFromBackend(idpUser?: any) {

      if(idpUser == undefined)
        return;

      let tempUser: User = {
        username: idpUser.preferred_username,
        firstname: idpUser.given_name,
        lastname: idpUser.family_name,
        sid: idpUser.sid,
        email: idpUser.email
      };

      this.setUser(tempUser);

      this.apiService.get("/user/me").subscribe({
        next: (res) => {
          console.log("idpUserRes", res);
          this.setUser(res.data);
        }});
    }


    updateUserSettingDetails(newDetails: UserSettingDetail[] | null | undefined) {

      if(!newDetails)
        return

      let currentUser = this.userSubject$.value;

      if(currentUser?.userSettings)
        currentUser.userSettings.userSettingDetails = newDetails;

      this.setUser(currentUser);

    }
}
