<mat-form-field class="reference-data-input-field" appearance="fill">
  <mat-label>{{nameFields[0] | translate}}</mat-label>

  <mat-select
    [compareWith]="compareFn"
    #itemListCtrl
    (selectionChange)="selectedItemChange.emit(selectedItem)"
    [(value)]="selectedItem"
    (keydown)="preventScroll($event)"
    class="cgi-search-select"
    [formControl]="itemList"
    (openedChange)="bootstrap($event)"
  >
    <mat-option #keywordCtrlOption class="input-option" [disabled]="true">
      <mat-form-field>
        <input #keywordCtrl
          (keydown)="preventScroll($event)"
          class="_nrp_ss_keyword-input"
          [formControl]="keyword"
          type="text"
          name="keyword"
          matInput
          autocomplete="off"
          placeholder="{{'Type to search' | translate}}"
        />
      </mat-form-field>
    </mat-option>
    <mat-option class="no-results" [disabled]="true" *ngIf="items?.length === 0"> {{'Results not found' | translate}}</mat-option>
    <mat-option class="search-result" *ngFor="let item of items" [value]="item">
      <span class="name-field" *ngFor="let nameField of nameFields">
            {{item[nameField]}}
      </span>
    </mat-option>
    <mat-option *ngIf="scrollLoading" [disabled]="true"> {{'Loading..' | translate}} </mat-option>
    <mat-option *ngIf="isLastPage && items.length !== 0" [disabled]="true">
      {{'No more results found.' | translate}}
    </mat-option>
  </mat-select>
</mat-form-field>
