import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from "../../models/select-item.model";
import {SharedMetaItem} from "../../models/shared.model";
import {User, UserSettingDetail} from "../../models/user.model";
import {ApiService} from "../../services/api.service";
import {ToastService} from "../../services/toast.service";
import {UserService} from "../../services/user.service";
import {MatDialog} from "@angular/material/dialog";
import {StringValueType} from "../../models/value-type.model";
import {SaveFilterDialogComponent} from "./save-filter-dialog/save-filter-dialog.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cgi-filter',
  templateUrl: './cgi-filter.component.html',
  styleUrls: ['./cgi-filter.component.scss'],
})
export class CgiFilterComponent {

  showFilterArea = false;

  targetSelectedUserFilter?: string;

  groupedUserSavedFilter?: string[];

  authedUser?: User;

  translations?: any;

  /**
   * Holds the current value of the slider
   */
  @Input() filterPageType?: 'saved_filter_work_list' | 'saved_filter_work_list_send';

  /**
   * Holds the current value of the slider
   */
  @Input() parsedAvailableData?: SelectItem[];

  /**
   * Holds the current value of the slider
   */
  @Input() rawAvailableData?: StringValueType[];

  /**
   * Holds the current value of the slider
   */
  @Input() selectedFilters?: SelectItem[];

  /**
   * Holds the current value of the slider
   */
  @Input() sharedMetaItems: SharedMetaItem[] | undefined;
  
  /**
   * Whether or not to wrap the contents of this component in <mat-card>
   */
  @Input() useCardWrapper?: boolean = true;

  /**
   * Whether or not to display the header "Filter" at the top of the component
   */
  @Input() displayHeader?: boolean = true;

  /**
   * Invoked when the model has been changed
   */
  @Output() selectedFiltersChange: EventEmitter<SelectItem> = new EventEmitter<SelectItem>();

  /**
   * Invoked when the model has been changed
   */
  @Output() triggerSearchChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Invoked when the model has been changed
   */
  @Output() triggerClearChange: EventEmitter<boolean> = new EventEmitter<boolean>();



  constructor(private cdr: ChangeDetectorRef,
              private _apiService: ApiService,
              private _toastService: ToastService,
              private _userService: UserService,
              private _dialog: MatDialog,
              private _translate: TranslateService,
  ) {
  }

  ngOnInit() {

    this._userService.listenToAuthedUser().subscribe(x => {
      this.authedUser = x;

      this._translate.get(
        ['No filters with value to save', 'new filter items added for the', 'the group']
      ).subscribe(trans => {
        this.translations = trans;
      });

      this.groupedUserSavedFilter = this.authedUser?.userSettings?.userSettingDetails?.filter(x => x.type == this.filterPageType)
        .map(x => x.groupName)
        .filter(function(elem, index, self) {
          return index === self.indexOf(elem);
        }) as string [] | undefined;

    });



  }

  getSelectedItems(): SelectItem[] {
    for (let c of this.rawAvailableData ?? []) {
      let found = this.selectedFilters?.find(x => x.item_id == c.value);

      if(found)
        found.item_type = c.type;
    }
    return this.selectedFilters ?? [];
  }


  changedSavedFilter(selectedGroupName: any) {

    this.onClear(false);

    if(selectedGroupName) {

      const savedFilters = this.authedUser?.userSettings?.userSettingDetails?.filter(x => x.type == this.filterPageType && x.groupName == selectedGroupName);

      if(savedFilters) {
        for(let savedFilter of savedFilters) {
          if (savedFilter.value && savedFilter.key) {
            const matchingSelectedFilter: SelectItem | undefined = this.selectedFilters?.find(f => f.item_id == savedFilter.key);
            if (matchingSelectedFilter) {
              matchingSelectedFilter.item_value = savedFilter.value;
            } else {
              const savedFilterPreset: SelectItem | undefined = this.parsedAvailableData?.find(x => x.item_id == savedFilter.key);
              if (savedFilterPreset) {
                this.selectedFilters?.push({
                  item_id: savedFilter.key,
                  item_value: savedFilter.value,
                  item_text: savedFilterPreset.item_text, // The responsibility to translate this text should be on the parent component.
                  item_type: savedFilterPreset.item_type
                });
              }
            }
          }
        }
      }
    }
  }

  deleteSavedFilter($event: MouseEvent, groupName: string) {

    this._apiService.delete('/user/setting/favorite_filter?groupName='+groupName).subscribe({
      next: (res: any) => {
        console.log('/user/setting/body res', res);
        this._toastService.open(res.data);
        const newList = this.authedUser?.userSettings?.userSettingDetails?.filter(x => x.type == this.filterPageType && x.groupName != groupName);
        this._userService.updateUserSettingDetails(newList);
      },
      error: (error: any) => {
        console.log("Error deleting filter", error);
      }
    });

  }

  dateRangeChange(selectedItem: SelectItem, dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    const dateRange: string = `${dateRangeStart.value},${dateRangeEnd.value}`;
    selectedItem.item_value = (dateRange === ',') ? undefined : dateRange;
  }

  buildDateInput(dateRangeString: string | undefined, startOrEndDate: 'start' | 'end'): string | null {
    if (dateRangeString) {
      const dateRangeSplit: string[] = dateRangeString.split(',');
      return startOrEndDate == 'start' ? dateRangeSplit[0] : dateRangeSplit[1];
    }
    return null;
  }

  getStringElemDropdownItems(fieldItemId: string): SharedMetaItem[] | undefined {
    return this.sharedMetaItems?.filter(x => x.type == 'dropdown' && x.key == fieldItemId);
  }

  onClear(isLoadNewData: boolean = true) {

    for (let item of this.selectedFilters ?? []) {
      item.item_value = '';
    }

    this.targetSelectedUserFilter = undefined;

    this.triggerClearChange.emit(isLoadNewData);
  }


  openSaveFilterDialog(): void {

    const selectedFilterWithValue = this.selectedFilters?.filter(x => x.item_value);

    if(selectedFilterWithValue?.length == 0) {
      this._toastService.open(this.translations['No filters with value to save']);
      return;
    }

    const dialogRef = this._dialog.open(SaveFilterDialogComponent, {
      data: {favoriteFilterGroupName: ""},
    });

    dialogRef.afterClosed().subscribe(typedGroupName => {

      console.log('The dialog was closed', typedGroupName);

      if(!typedGroupName)
        return

      const body: UserSettingDetail[] | undefined = selectedFilterWithValue?.map((value) => {
        return {
          type: this.filterPageType,
          key: value.item_id,
          value: value.item_value as string,
          groupName: typedGroupName
        }
      });

      if(!body)
        return

      this._apiService.post('/user/setting/favorite_filter', body).subscribe({
        next: (res: any) => {
          console.log('/user/setting/body res', res);
          const data = res.data as UserSettingDetail[];

          console.log('data users', data);

          this._userService.updateUserSettingDetails(data);
          this.targetSelectedUserFilter = typedGroupName;
          this._toastService.open(data.filter(x => x.groupName == typedGroupName).length + " " + this.translations["new filter items added for the"] + " " + typedGroupName + " " + this.translations["the group"]);
        },
        error: (error: any) => {
          console.log("Error saving filter", error);
        }
      });


    });
  }



}
