import {StringValueType} from "./value-type.model";

export interface ResponseSentExaminations {
  totalNr: number;
  examinationsSent: SpExaminationsSent[];
}

export interface SpExaminationsSent {
  /**
   * Format: int64
   * @description Gets or sets the value of the id
   */
  id?: number;
  /**
   * Format: date-time
   * @description Gets or sets the value of the insert time
   */
  insertTime?: string;
  /**
   * Format: date-time
   * @description Gets or sets the value of the updated time
   */
  updatedTime?: string;
  /**
   * Format: int64
   * @description Gets or sets the value of the assignment id
   */
  assignmentId?: number;
  /**
   * Format: int64
   * @description Gets or sets the value of the origin user care unit id
   */
  originUserCareUnitId?: number;
  /** @description Gets or sets the value of the origin user care unit name */
  originUserCareUnitName?: string;
  /**
   * Format: int32
   * @description Gets or sets the value of the dest user response
   */
  destUserResponse?: number;
  /**
   * Format: date-time
   * @description Gets or sets the value of the dest user response time
   */
  destUserResponseTime?: string;
  /**
   * Format: int32
   * @description Gets or sets the value of the result status
   */
  resultStatus?: number;
  /** @description Gets or sets the value of the expected result */
  expectedResult?: string;
  /**
   * Format: date-time
   * @description Gets or sets the value of the assignment creation time
   */
  assignmentCreationTime?: string;
  /**
   * Format: date-time
   * @description Gets or sets the value of the result received time
   */
  resultReceivedTime?: string;
  /** @description Gets or sets the value of the assignment priority */
  assignmentPriority?: string;
  /**
   * Format: int32
   * @description Gets or sets the value of the is timeout
   */
  isTimeout?: number;
  /**
   * Format: int32
   * @description Gets or sets the value of the is timeout confirmed
   */
  isTimeoutConfirmed?: number;
  /** @description Gets or sets the value of the filler order number */
  fillerOrderNumber?: string;
  /** @description Gets or sets the value of the filler request id */
  fillerRequestId?: string;
  /** @description Gets or sets the value of the filler examination code */
  fillerExaminationCode?: string;
  /** @description Gets or sets the value of the body part name */
  bodyPartName?: string;
  /** @description Gets or sets the value of the examination status */
  examinationStatus?: string;
  /** @description Gets or sets the value of the extra status */
  extraStatus?: string;
  /** @description Gets or sets the value of the procedure name */
  procedureName?: string;
  /** @description Gets or sets the value of the case priority */
  casePriority?: string;
  /** @description Gets or sets the value of the accession number */
  accessionNumber?: string;
  /** @description Gets or sets the value of the patient id */
  patientId?: string;
  /** @description Gets or sets the value of the first name */
  firstName?: string;
  /** @description Gets or sets the value of the last name */
  lastName?: string;
  /**
   * Format: int64
   * @description Gets or sets the value of the care unit destination id
   */
  careUnitDestinationId?: number;
  /** @description Gets or sets the value of the care unit destination name */
  careUnitDestinationName?: string;
  /**
   * Format: int64
   * @description Gets or sets the value of the provider destination id
   */
  providerDestinationId?: number;
  /** @description Gets or sets the value of the provider destination name */
  providerDestinationName?: string;
  /** @description Gets or sets the value of the unit destination name */
  unitDestinationName?: string;
  /** @description Gets or sets the value of the producing unit name */
  producingUnitName?: string;
  /** @description Gets or sets the value of the producing unit hsa id */
  producingUnitHsaId?: string;
  /** @description Gets or sets the value of the collaboration sender */
  collaborationSender?: string;

}

export const availableSentExaminationColumns: StringValueType[] = [
  {value: 'id', type: 'number'},
  {value: 'insertTime', type: 'date'},
  {value: 'updatedTime', type: 'date'},
  {value: 'assignmentId', type: 'number'},
  {value: 'originUserCareUnitId', type: 'number'},
  {value: 'originUserCareUnitName', type: 'string'},
  {value: 'destUserResponse', type: 'number'},
  {value: 'destUserResponseTime', type: 'date'},
  {value: 'resultStatus', type: 'number'},
  {value: 'expectedResult', type: 'string'},
  {value: 'assignmentCreationTime', type: 'date'},
  {value: 'resultReceivedTime', type: 'date'},
  {value: 'assignmentPriority', type: 'string'},
  {value: 'isTimeout', type: 'boolean'},
  {value: 'isTimeoutConfirmed', type: 'boolean'},
  {value: 'fillerOrderNumber', type: 'string'},
  {value: 'fillerRequestId', type: 'string'},
  {value: 'fillerExaminationCode', type: 'string'},
  {value: 'bodyPartName', type: 'string'},
  {value: 'examinationStatus', type: 'string'},
  {value: 'extraStatus', type: 'string'},
  {value: 'procedureName', type: 'string'},
  {value: 'casePriority', type: 'string'},
  {value: 'accessionNumber', type: 'string'},
  {value: 'patientId', type: 'string'},
  {value: 'firstName', type: 'string'},
  {value: 'lastName', type: 'string'},
  {value: 'careUnitDestinationId', type: 'number'},
  {value: 'careUnitDestinationName', type: 'string'},
  {value: 'providerDestinationId', type: 'number'},
  {value: 'providerDestinationName', type: 'string'},
  {value: 'unitDestinationName', type: 'string'},
  {value: 'producingUnitName', type: 'string'},
  {value: 'producingUnitHsaId', type: 'string'},
  {value: 'collaborationSender', type: 'string'}
];


