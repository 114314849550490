import {Routes} from '@angular/router';

import {FullComponent} from './layouts/full/full.component';
import {AppBlankComponent} from './layouts/blank/blank.component';
import {AuthGuard} from "./shared/auth-guard/auth.guard";
import {HomeComponent} from "./pages/shared/home/home.component";
import {ProfileComponent} from "./pages/shared/profile/profile.component";
import {ExportsDownloadComponent} from "./pages/admin-portal/exports/download/exports-download.component";

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
        data: {
          title: 'Home'
        },
      },
      {
        path: 'exports/:identifier',
        component: ExportsDownloadComponent,
        data: {
          title: 'Download Export',
          urls: [{ title: 'Exports' }],
        },
      }
      ]
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          title: 'Profile',
          urls: [{title: 'Home', url: '/home'}, {title: 'Profile'}],
        },
      },
      {
        path: 'admin-portal',
        loadChildren: () =>
          import('./pages/admin-portal/admin-portal.module').then((m) => m.AdminPortalModule),
      },
      {
        path: 'collaboration-portal',
        loadChildren: () =>
          import('./pages/collaboration-portal/collaboration-portal.module').then((m) => m.CollaborationPortalModule),
      },
      {
        path: 'other',
        loadChildren: () => import('./pages/other/other.module').then((m) => m.OtherModule),
      },
    ],
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'error',
        loadChildren: () =>
          import('./pages/shared/errors/errors.module').then((m) => m.ErrorsModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];
