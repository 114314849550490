import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface ConfirmDialogData {
  message: string;
  identifier?: string;
  okButtonText?: string;
  okButtonColor?: string;
  title?: string;
  warningText?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-send-dialog.component.html',
  styleUrls: ['confirm-send-dialog.component.scss'],
})
export class ConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
