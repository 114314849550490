import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {SelectItem} from "../../models/select-item.model";

@Component({
  selector: 'app-cgi-multiselect',
  templateUrl: './cgi-multiselect.component.html',
  styleUrls: ['./cgi-multiselect.component.scss'],
})
export class CgiMultiselectComponent {

  /**
   * Holds the current value of the slider
   */
  @Input() placeholderText: string = "";

  /**
   * Holds the current value of the slider
   */
  @Input() isDropdownDisabled: boolean = false;

  /**
   * Invoked when the model has been changed
   */
  @Output() isDropdownDisabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  /**
   * Holds the current value of the slider
   */
  @Input() dataValue: SelectItem[] = [];

  /**
   * Invoked when the model has been changed
   */
  @Output() dataValueChange: EventEmitter<SelectItem[]> = new EventEmitter<SelectItem[]>();

  /**
   * Holds the current value of the slider
   */
  @Input() selectedItems: SelectItem[] = [];

  /**
   * Invoked when the model has been changed
   */
  @Output() selectedItemsChange: EventEmitter<SelectItem[]> = new EventEmitter<SelectItem[]>();


  //dropdownSettings?: IDropdownSettings;

  isUpdate = false;

  constructor(private cdr: ChangeDetectorRef, private toastService: MatSnackBar) {
  }

  ngOnInit() {

    /*
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Välj alla',
      unSelectAllText: 'Välj inga',
      searchPlaceholderText: 'Sök...',
      //itemsShowLimit: 3,
      allowSearchFilter: true
    };

     */
  }

  remove(fruit: SelectItem): void {
    const index = this.selectedItems.indexOf(fruit);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
    }
  }




}
