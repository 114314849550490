import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {AppRoutes} from './app.routing';
import {AppComponent} from './app.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FullComponent} from './layouts/full/full.component';
import {AppBlankComponent} from './layouts/blank/blank.component';
import {VerticalAppHeaderComponent} from './layouts/full/vertical-header/vertical-header.component';
import {VerticalAppSidebarComponent} from './layouts/full/vertical-sidebar/vertical-sidebar.component';
import {AppBreadcrumbComponent} from './layouts/full/breadcrumb/breadcrumb.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {SpinnerComponent} from './shared/spinner.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {OAuthModule} from "angular-oauth2-oidc";
import {AuthGuard} from "./shared/auth-guard/auth.guard";
import {HomeComponent} from "./pages/shared/home/home.component";
import {mailGlobalVariable, mailService} from "./pages/other/apps/mailbox/mail.service";
import {MaterialModule} from "./shared/material-module";
import {ProfileComponent} from './pages/shared/profile/profile.component';
import {TranslatedMatPaginator} from "./shared/providers/translated-mat-paginator";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from "@angular/material/snack-bar";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    VerticalAppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    VerticalAppSidebarComponent,
    AppBreadcrumbComponent,
    HomeComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes, {}),
    OAuthModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: TranslatedMatPaginator,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {duration: 5000}
    },
    mailService,
    mailGlobalVariable,
    DatePipe,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
