import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {availableExaminationColumns} from "../models/sp-examination.model";

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  // Http Headers

  constructor(private _translate: TranslateService, private _toastService: MatSnackBar) {}

  open(message: string, action?: string, config?: MatSnackBarConfig) {

    this._translate.get(['Close']).subscribe(trans => {

      action ??= trans['Close'];

      config ??= {
        duration: 12000
      };

      this._toastService.open(message, action, config);

    });

  }

}
