import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TreeUnitNode } from '../models/ct-unit.model';

@Injectable({
    providedIn: 'root'
})
export class StateService {

  private themeChangedSource = new Subject<Theme>();
  private examinationDestinationChangedSource = new Subject<TreeUnitNode>();
  
  // Default theme to light theme.
  private _currentTheme: Theme = Theme.Light;
  private _currentExaminationDestination: TreeUnitNode | undefined = undefined;

  public readonly THEME_SETTING_LOCAL_STORAGE_KEY: string = 'theme-setting';

  public themeChanged$ = this.themeChangedSource.asObservable();
  public examinationDestinationChanged$ = this.examinationDestinationChangedSource.asObservable();

  changeTheme(theme: Theme) {
    this._currentTheme = theme;
    this.themeChangedSource.next(theme);
  }

  get currentTheme(): Theme {
    return this._currentTheme;
  }

  changeExaminationDestination(destination: TreeUnitNode) {
    this._currentExaminationDestination = destination;
    this.examinationDestinationChangedSource.next(destination);
  }

  get currentExaminationDestination(): TreeUnitNode | undefined {
    return this._currentExaminationDestination;
  }
}

export enum Theme {
  Light = 'light',
  Dark = 'dark'
}