import {Component} from '@angular/core';
import {AuthConfig, OAuthService} from "angular-oauth2-oidc";
import {UserService} from "./shared/services/user.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {

  constructor(private oauthService: OAuthService, private userService: UserService, private translate: TranslateService) {

    //translate.setDefaultLang('sv');
    //translate.use('sv');

    const authCodeFlowConfig: AuthConfig = {
      // Url of the Identity Provider
      issuer: environment.oidcIssuer, //'https://auth.cgikna.se/realms/gitlabs',

      // URL of the SPA to redirect the user to after login-oidc
      //redirectUri: window.location.origin + '/index.html',
      redirectUri: window.location.origin + '/home',

      // The SPA's id. The SPA is registerd with this id at the auth-server
      // clientId: 'server.code',
      clientId: 'samtj-web',

      // Just needed if your auth server demands a secret. In general, this
      // is a sign that the auth server is not configured with SPAs in mind
      // and it might not enforce further best practices vital for security
      // such applications.
      // dummyClientSecret: 'secret',

      responseType: 'code',

      // set the scope for the permissions the client should request
      // The first four are defined by OIDC.
      // Important: Request offline_access to get a refresh token
      // The api scope is a usecase specific one
      scope: 'openid profile email offline_access roles samtj-api',

      showDebugInformation: true,
    };

    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();

    this.oauthService.events.subscribe(x => {

      console.log("OAuthInfoEvent", x);

      // @ts-ignore
      if(x.type == "token_expires" && x["info"] == "access_token") {
        console.log("refreshToken");
        this.oauthService.refreshToken().then(y => console.log("y", y));

      } else if(x.type == "token_received" || (x.type == "discovery_document_loaded" && this.oauthService.hasValidAccessToken() )) {
        this.oauthService.loadUserProfile().then(p => {
          this.userService.getUserFromBackend(p)
        });
      }
    })

  }
}
