import {ChangeDetectorRef, Component, OnDestroy, Renderer2} from '@angular/core';
import {User} from "../../../../shared/models/user.model";
import {ApiService} from "../../../../shared/services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../../shared/services/user.service";
import {ActivatedRoute} from "@angular/router";
import {DicomStudyDto} from "../../../../shared/models/dicom-study.model";
import {JsonDetailsDialogComponent} from "../../../../shared/dialog/json-details-dialog/json-details-dialog.component";
import {ToastService} from "../../../../shared/services/toast.service";
import {ExportJobDto} from "../../../../shared/models/exports.model";
import {Subscription} from "rxjs";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-exports-download',
  templateUrl: './exports-download.component.html',
  styleUrls: ['./exports-download.component.scss']
})
export class ExportsDownloadComponent implements OnDestroy {

  authedUser: User | undefined;

  disposable: any;

  exportJob?: ExportJobDto

  private routeSub?: Subscription;

  constructor(private _cdr: ChangeDetectorRef,
              private _renderer: Renderer2,
              private _apiService: ApiService,
              private _dialog: MatDialog,
              private _translate: TranslateService,
              private _userService: UserService,
              private _toastService: ToastService,
              private _route: ActivatedRoute) {
  }

  ngOnInit() {

    this.routeSub = this._route.params.subscribe(params => {
      console.log(params) //log the entire params object

      this.loadDownloadData(params['identifier']);

    });

    this.disposable = this._userService.listenToAuthedUser().subscribe(x => {
      this.authedUser = x;

      if(!this.authedUser?.username)
        return;

    });

  }

  showDetails(item: DicomStudyDto | ExportJobDto) {

    const enterAnimationDuration = '100ms';
    const exitAnimationDuration = '100ms';

    const dialogRef = this._dialog.open(JsonDetailsDialogComponent, {
      width: '80%',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        detail: item,
      }
    });

    dialogRef.afterClosed().subscribe(selectedExaminationsObj => {

      if (!selectedExaminationsObj)
        return

    });

  }

  private loadDownloadData(identifier: string) {

    this._cdr.detectChanges();

    const uri = `/export/${identifier}`;

    this._apiService.getNoAuth(uri).subscribe({
      next: (res: any) => {
        const data = res.data as ExportJobDto;
        console.log("res.data", data);

        this.exportJob = data;

      },
      error: (error: any) => {
        console.log("error examinations", error);
      }});

  }

  ngOnDestroy() {
    this.disposable.unsubscribe();
    this.routeSub?.unsubscribe();
  }


  prepareExport() {

    const uri = `/export/prepare/${this.exportJob?.identifier}`;

    this._apiService.putNoAuth(uri).subscribe({
      next: (res: any) => {
        const data = res.data as ExportJobDto;
        console.log("res.data", data);

        this.exportJob = data;

      },
      error: (error: any) => {
        console.log("error examinations", error);
      }});

  }

  download() {
    const link = this._renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', + `${ environment.apiBaseUrl}/export/prepare/${this.exportJob?.identifier}`);
    link.setAttribute('download', `${ this.exportJob?.identifier}.zip`);
    link.click();
    link.remove();
  }

}
